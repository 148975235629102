import isEmpty from './isEmpty';

export const truncateAfterDecimal = ({ value, fractionDigits = 2 }) => {
  if (isEmpty(value)) {
    return value;
  }

  const indexSepearator = value.search(/[.,]/);

  if (indexSepearator === -1) {
    return value;
  }

  return value.toString().slice(0, indexSepearator + fractionDigits + 1);
};
