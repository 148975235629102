import Notify from 'quasar/src/plugins/Notify.js';import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;

export { default as nestedFreeze } from './nestedFreeze';
export { default as isEmpty } from './isEmpty';
export { default as isPositiveNumber } from './isPositiveNumber';
export { hashStringToColor } from './hashStringToColor';
export { default as authUtils } from './auth';
export { default as localStorageManager } from './localStorageManager';
export { default as notify } from './notify';
export { default as dialogs } from './dialogs';
export { default as routerMiddlewares } from './routerMiddlewares';
export { default as objectUtils } from './objects';
export { default as validations } from './validations';
export { unauthorizedHandler } from './unauthorizedHandler';
export { truncateAfterDecimal } from './truncateAfterDecimal';

export const copyToBuffer = async (val) => {
  try {
    await copyToClipboard(val);
    Notify.create({
      message: 'Copied!',
    });
  } catch (e) {
    Notify.create({
      message: 'Something went wrong.',
    });
    console.error(e);
  }
};

export const debounce = (func, timeout) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const flatObjectToArrayOfObjects = (object) =>
  Object.entries(object).map(([key, value]) => ({
    key,
    value,
  }));

export const arrayOfObjectsToFlatObject = (arr) =>
  arr.reduce((acc, el) => ({ ...acc, [el.key]: el.value }), {});

export const formatAmount = (number, locale, options) =>
  number.toLocaleString(locale, options);

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export function cloneStructured(value) {
  if (!value) return value;

  return JSON.parse(JSON.stringify(value));
}

export function getSymbolCurrency(currency) {
  if (currency === 'TRY') {
    return '₺';
  }

  return (0)
    .toLocaleString('ru-RU', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .slice(2);
}

export function numberToFixed(value, fractionDigits = 2) {
  const localValue = value && +value ? +value : 0;
  const [wholePart, decimalPart = ''] = localValue.toFixed(8).split('.');

  return +[wholePart, decimalPart.slice(0, fractionDigits)].join('.');
}

export function formatMoney({
  value,
  currency = 'EUR',
  showSymbol = true,
  maximumFractionDigits = 6,
}) {
  value = value && Number.isFinite(+value) ? +value : 0;
  value = numberToFixed(value, maximumFractionDigits);

  let num = value.toLocaleString('ru-RU', {
    currency,
    maximumFractionDigits,
  });

  if (showSymbol) {
    num += ` ${getSymbolCurrency(currency)}`;
  }

  return num;
}
